.bg_blur {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(0 0 0 / 40%);
  z-index: 1005;
  animation: smoothShowing 0.2s ease forwards;
}

@keyframes smoothShowing {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.header_top {
  padding: 10px 0;
}

.header_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_text .header_text_right {
  display: flex;
  justify-content: space-between;
  min-width: 250px;
  margin-left: 45px;
}

.header_work_schedule {
  margin-left: 10px;
}

.header_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  min-width: 200px;
}

.header_bottom {
  background: #FFCF28;
  position: relative;
  padding: 9px 0;
  top: 0;
  left: 0;
  right: 0;
}

.header_toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.header_sticky_bottom {
  position: relative;
  height: 63px;
  z-index: 1005;
}

.high_index {
  z-index: 30005;
}

.location,
.favorite,
.cart_icon {
  display: inline-flex;
  align-items: center;
}

@media (max-width: 2560px) {
  .header_sticky_bottom {
    height: 62px;
  }
}

@media (max-width: 1200px) {
  .header_info {
    white-space: nowrap;
  }

  .header .header_text_right {
    margin-left: 0;
  }

  .header .header_text_right a,
  .header .header_text_right span,
  .header .header_text_right b,
  .header .header_buttons a,
  .header .header_buttons b,
  .header .header_buttons span {
    font-size: 12px;
  }

  .header_sticky_bottom {
    height: 55px;
  }
}

@media (max-width: 921px) {
  .header_sticky_bottom {
    height: 50px;
  }
}

@media (min-width: 921px){
  .header_bottom {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .cart_icon {
    margin-left: 6px;
  }

  .header_bottom {
    background: #FFCF28;
    position: relative;
    padding: 0;
  }
}