.form {
  position: relative;
}

.max_width {
  max-width: 394px;
}

.form .form_row,
.form .form_column,
.form .form_center,
.form .form_left {
  display: flex;
  position: relative;
}

.label {
  display: flex;
  align-items: center;

  img + span {
    margin-left: 10px;
  }
}

.form .form_column {
  flex-direction: column;
  margin-left: 20px;
}

.form .form_row {
  flex-wrap: wrap;
  align-items: baseline;
  gap: 20px 50px;
  justify-content: space-between;
}

.form_row.no_wrap {
  flex-wrap: nowrap;
}

.form .radio_group {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.form .form_left > * {
  margin-right: 32px;
}

.form .form_item {
  display: flex;
  flex-direction: column;
  flex: 0 1 21.5%;
}

.form .form_item.item_center {
  align-self: stretch;
  display: flex;
  width: 100%;
}

.form .form_item .inner_center {
  flex: 1;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.form input[type="number"]::-webkit-outer-spin-button,
.form input[type="number"]::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  appearance: none;
  margin: 0;

  /* <-- Apparently some margin are still there even though it's hidden */
}

.form input[type="number"] {
  appearance: textfield;

  /* Firefox */
}

.form input[type="text"],
.form input[type="number"],
.form input[type="password"],
.form input[type="email"],
.form input[type="date"],
.form input[type="tel"],
.form select,
.form textarea {
  border: 1px solid #CCC;
  outline: none;
  border-radius: 5px;
  padding: 9px;
  font-size: 16px;
  box-shadow: none;
}

.form input[type="text"]:focus,
.form input[type="number"]:focus,
.form input[type="password"]:focus,
.form input[type="email"]:focus,
.form input[type="date"]:focus,
.form input[type="tel"]:focus,
.form select:focus,
.form textarea:focus {
  -webkit-box-shadow: 0 0 0 1px #ccc;
  -moz-box-shadow: 0 0 0 1px #ccc;
  box-shadow: 0 0 0 1px #ccc;
}

.form input[type="text"]:disabled,
.form input[type="email"]:disabled {
  color: #C4C4C4;
}

.form textarea {
  font-size: 14px;
  line-height: 14px;
  resize: none;
  width: 100%;
  border-radius: 12px;
}

.form input:read-only {
  color: #A8A8A8;
}

.form select {
  height: 38px;
  padding: 5px 9px;
}

.form .radio_item {
  margin-bottom: 15px;
}

.form .radio_item span {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}

.form .radio_item span::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #8E8E8E;
  border-radius: 50%;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.form .radio_group input:checked + span::before {
  border-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='5' fill='%23FFCF28'/%3E%3C/svg%3E%0A");
}

.form .radio_group input:checked + span {
  color: #000;
}

.form button {
  padding: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form button:disabled {
  cursor: not-allowed;
}

.form button > img {
  margin-right: 10px;
}

.form label {
  margin-bottom: 8px;
  color: #A8A8A8;
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.input_info .inner {
  display: flex;
  align-items: center;
  position: relative;
}

.input_info_img {
  width: 20px;
  height: 20px;
}

.input_info_img img {
  width: 100%;
  height: 100%;
}

.input_info:hover .input_info_text {
  display: block;
}

.input_info .input_info_text {
  display: none;
  position: absolute;
  width: 230px;
  right: 25px;
  background: rgb(0 0 0 / 80%);
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.154px;
  color: #FFF;
  z-index: 1;
}

.input_info .input_info_text::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgb(0 0 0 / 80%);
  position: absolute;
  right: -9px;
  bottom: 50%;
  transform: translateY(50%) rotate(-90deg);
}

.input_info.error .input_info_text {
  background-color: #FF4A4A;
  font-weight: 500;
}

.input_info.error .input_info_text::after {
  border-top: 6px solid #FE3F3F;
}

.form_warning {
  color: #FF5454;
  position: absolute;
  width: auto;
  left: 0;
  bottom: 18px;
  line-height: 16px;
}

.input_inner {
  position: absolute;
  top: 38px;
  right: 10px;
}

.check_item {
  display: flex;
  align-items: center;
}

.check_label {
  color: #000;
  font-size: 12px;
  line-height: 14px;
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.form input[type="checkbox"] + .check_label::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #9B9B9B;
  border-radius: 1px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  margin-right: 9px;
  margin-bottom: 2px;
}

.form input[type="checkbox"]:checked + .check_label::before {
  border-color: #FFD42F;
  background-color: #FFD42F;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.12402 4.80722L5.92494 8.04739L11.9128 1.00012' stroke='%23101010' stroke-width='2'/%3E%3C/svg%3E%0A");
}

.form input[type="checkbox"]:not(:disabled, :checked) + .check_label:hover::before {
  border-color: #8C8C8C;
}

.form input[type="checkbox"]:focus + .check_label::before {
  box-shadow: 0 0 0 2px rgb(140 140 140 / 25%);
}

.form input[type="checkbox"]:disabled + .check_label::before {
  background-color: #E9ECEF;
}

.form_row a,
.form_column a {
  font-weight: 500;
  font-size: 12px;
  color: #5587B4;
}

.form_center {
  flex-direction: column;
  align-items: center;
}

.form .form_big_btn {
  padding: 17px;
  width: 100%;
  font-size: 16px;
  background: #FFD400;
  border-radius: 12px;
  font-weight: 600;
  text-align: center;
  transition: background-color 0.3s ease;
}

.form_big_btn:hover {
  background-color: #F5CB00;
}

.form_big_btn:disabled {
  background: #E5E5E5;
  cursor: not-allowed;
}

.form_small_btn {
  font-weight: 500;
  text-align: left;
  justify-content: flex-start;
}

.form_small_btn:disabled {
  cursor: not-allowed;
}

.form_item.has_info .form_item_select,
.form_item.has_info input {
  margin-right: 30px;
}

.form_item.has_info.error input {
  border-color: #FF4A4A;
}

.input_info {
  position: absolute;
  right: 1px;
  bottom: -115%;
}

.form_item_desc {
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px;
  color: #A8A8A8;
  max-width: 394px;
}

.form_item_desc a {
  display: inline;
  color: #A8A8A8;
}

.form_item_desc b {
  font-weight: 500;
  font-size: 12px;
}

.forget_phone {
  padding-top: 8px;
  text-decoration: underline;
  color: #3342AA;
  cursor: pointer;
  align-self: flex-start;
}

.form_item.gender {
  label:first-child {
    margin-bottom: 3px;
  }
}

.gender_row {
  display: flex;

  label {
    color: #000;
    margin-left: 10px;
  }

  > div:first-child {
    margin-right: 57px;
  }

  .radio {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
}

/* dinos */
.new_phone_dino {
  position: absolute;
  top: -156px;
  z-index: -1;
}

.code_verification_dino {
  position: absolute;
  top: -186px;
}

.confirm_code_dino {
  position: absolute;
  top: -140px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.success_auth__image {
  position: absolute;
  bottom: 100%;
  left: 20%;
}

.warning {
  margin-top: 15px;
}

.warning > .link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.part_input {
  display: flex;
  flex-direction: row !important;
}

.part_input input {
  margin-right: 15px;
  width: 85px;
  text-align: center;
}

.part_input input:last-child {
  margin-right: 0;
}

@media (max-width: 1439px) {
  .form .form_item {
    flex: 0 1 25%;
  }
}

@media (max-width: 1199px) {
  .form .form_row {
    gap: 30px;
  }

  .form .form_item {
    flex: 0 1 30%;
  }
}

@media (max-width: 991px) {
  .form .form_row {
    gap: 20px;
  }

  .form .form_item {
    flex: 0 1 100%;
  }

  .form .form_item:first-child {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .form .form_left button {
    padding: 17px 0;
  }
}

@media (max-width: 575px) {
  .form .form_row,
  .form .form_column,
  .form .form_center {
    margin-top: 10px;
  }

  .success_auth__image,
  .new_phone_dino,
  .code_verification_dino,
  .confirm_code_dino {
    display: none;
  }

  .part_input {
    display: flex;
  }

  .part_input input {
    width: 35px;
    flex: 1 1;
  }
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .heading {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;

    h5 {
      color: #000;
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
    }

    span {
      color: #000;
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
    }

    .edit {
      display: flex;
      gap: 6px;
      align-items: center;
      background-color: #FFCF28;
      border-radius: 12px;
      padding: 8px 12px;

      img {
        height: 24px;
        width: 24px;
        margin: 0;
      }

      span {
        color: #000;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .contacts {
    display: flex;
    flex-wrap: nowrap;
    
    @media (min-width: 425px){
      gap: 32px;
    }

    &.edit {
      flex-direction: column;
      gap: 16px;

      label {
        margin-left: 10px;
      }
    }
  }

  .input {
    border-radius: 5px;
    border: 1px solid #A5A5A5;
    background: #FFF;
    padding: 10px;
  }

  .checkboxWrapper {
    justify-content: space-between;

    label {
      margin: 0 !important;
      color: #000;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
